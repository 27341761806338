import { createSpeechlySpeechRecognition } from '@speechly/speech-recognition-polyfill';
const appId = '1b0a73e8-a914-40ff-bcc2-bf9a4935bf7f';

let RECOGNIZER_INSTANCE: Recognizer;

export class Recognizer {
    public onresult: (event: { results: string | any[]; }) => void;
    private _isStarted: boolean;
    private _recognizer: any;

    constructor() {
        this._isStarted = false;
        if ("webkitSpeechRecognition" in window) {
            let recognizerType: any = window['webkitSpeechRecognition'];
            this._recognizer = new recognizerType();
        } else if ("SpeechRecognition" in window) {
            let recognizerType: any = window['SpeechRecognition'];
            this._recognizer = new recognizerType();
        } else {
            let recognizerType: any = createSpeechlySpeechRecognition(appId);
            this._recognizer = new recognizerType();
        }
        this._recognizer.continuous = true;
        this.onresult = (event: { results: string | any[]; }) => {};
        this._recognizer.onend = () => { // to undo spontaneous stops from the browser
            if (this._isStarted) { this._recognizer.start();}
        }
        this.bindCallback();
    }

    static Instance(): Recognizer {
        if (RECOGNIZER_INSTANCE === null || typeof RECOGNIZER_INSTANCE === "undefined") {
            RECOGNIZER_INSTANCE = new Recognizer();
        }
        return RECOGNIZER_INSTANCE;
    }

    start = () => {
        if (!this._isStarted) {
            this._recognizer.start();
            this._isStarted = true;
        }
    }

    stop = () => {
        if (this._isStarted) {
            this._isStarted = false; // this must be called before _recognizer.stop()
            this._recognizer.stop();
        }
    }

    restart = () => {
        this.stop();
        this.start();
    }

    private resultCallback = (event: { results: string | any[]; }) => {
        this.onresult(event);
    }

    private bindCallback = () => {
        this._recognizer.onresult = (event: { results: string | any[]; }) => {
            this.resultCallback(event);
        }
    }

    get isStarted() {
        return this._isStarted;
    }
}