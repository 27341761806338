import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import {ExamScore, HistoryData} from "../utils/typedefs";
import { COLOR, HISTORY_DOMAIN } from "../utils/constants"

export interface historyChartProps {
    data: ExamScore[],
    graphHeight?: number | string,
    graphwidth?: number | string, // graphHeight and graphWidth cannot both be strings
    tickGap?: number,
    lineWidth?: number,
    dotWidth?: number,
}

export const HistoryChart: React.FC<historyChartProps> = (props: historyChartProps) => {
    const getTicks = (): number[] => {
        let ticks: number[] = [];
        let step: number = (props.tickGap && props.tickGap <= HISTORY_DOMAIN.MAXIMUM) ? props.tickGap : 3;

        for(let i = HISTORY_DOMAIN.MINIMUM; i <= HISTORY_DOMAIN.MAXIMUM; i += step)
        {
            ticks.push(i);
        }

        return ticks;
    }

    return (
        <div className="historyGraphWrapper">
            <ResponsiveContainer width={(props.graphwidth) ? props.graphwidth : "100%"} height={(props.graphHeight) ? props.graphHeight : 500}>
                <LineChart
                    data={props.data}
                    margin={{
                        top: 5,
                        right: 45,
                        left: 10,
                        bottom: 5,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false}></CartesianGrid>
                    <XAxis dataKey="date" />
                    <YAxis ticks={getTicks()} domain={[HISTORY_DOMAIN.MINIMUM, HISTORY_DOMAIN.MAXIMUM]}/>
                    <Tooltip />
                    <Line 
                        type="monotone" 
                        dataKey="score" 
                        stroke={COLOR.SKY} 
                        strokeWidth={(props.lineWidth) ? props.lineWidth : 8} 
                        dot={{ stroke: COLOR.COPPER, strokeWidth: (props.dotWidth) ? props.dotWidth : 12 }} 
                        />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}