import { sessionLanguage } from "../utils/constants";
import { INVALID_STRING, Language } from "../utils/typedefs"
import { Translation } from "./translate_list"

export const translate = (id: Translation, forceLanguage?: Language): string => {
    let language: Language = Language.ENGLISH;
    if (typeof forceLanguage !== "undefined") {
        language = forceLanguage;
    } else {
        let storedLanguage = sessionStorage.getItem(sessionLanguage);
        if (storedLanguage !== null && typeof storedLanguage !== "undefined") {
            language = storedLanguage as Language;
        }
    }
    switch (language) {
        case Language.ENGLISH: {
            switch (id) {
                // Navigation and login
                case Translation.LOGIN: return "Login";
                case Translation.HOME: return "Home";
                case Translation.EXAM: return "Test";
                case Translation.HISTORY: return "History";
                case Translation.USERNAME: return "Username";
                case Translation.PASSWORD: return "Password";
                case Translation.SUBMIT: return "Submit";
                case Translation.LOG_IN: return "Log In";

                // Exam
                case Translation.VOICE_NOT_SUPPORTED: return "Your browser does not support voice recognition. Please try to access the test using Chrome or Safari.";
                case Translation.SETUP_PREAMBLE: return "Before you begin the exam, we need to make sure your microphone is set up properly. Please make sure your microphone is plugged in.";
                case Translation.EXAM_INSTRUCTIONS: return "This test may seem a bit difficult in the beginning, but usually it gets easier as we go along. I'm going to read for you a long list of words. Once I'm done, I'd like to see how many of the words you can recall. You can repeat the words in any order that you prefer, and you don't have to use the same order that I use. Then I'm going to read the same list to you a few more times to see how many of the words you can eventually learn. Ready?";
                case Translation.EXAM_INSTRUCTIONS_SECOND: return "That was a good beginning. Now I'm going to read the same list again, and again I'd like to see how many of the words you can recall, including the words you remembered on the first trial. Again, listen very carefully. Ready?";
                case Translation.EXAM_INSTRUCTIONS_REPEAT: return "Now I'm going to read the same list again, and again I'd like to see how many of the words you can recall, including the words you remembered on previous trials. Again, listen very carefully. Ready?";
                case Translation.EXAM_INSTRUCTIONS_READING: return "";
                case Translation.EXAM_INSTRUCTIONS_LISTENING: return "Repeat back as many words as you remember. When you are finished, click the button below to proceed.";

                // General
                case Translation.MEMORY_AIR: return "Memory Air";
                
                // Undefined
                default: return INVALID_STRING;
            }
        }
        default: return INVALID_STRING;
    }
}

