import "../styles/Home.css";
import "../styles/Calendar.css";
import React from "react";
import { History } from "../history/history";
import Calendar from 'react-calendar';
import { addExamScore } from "../utils/server_api";
import { HistoryData } from "../utils/typedefs";

export interface welcomeProps {
    isLoggedIn: boolean,
    launchTest: () => void,
    nextExamDate: Date,
    historyData: HistoryData[]
}

export const Home: React.FC<welcomeProps> = (props: welcomeProps) => {
    const [date, setDate] = React.useState<Date>(props.nextExamDate);
    const [showCal, setShowCal] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.nextExamDate > new Date()) {
            setShowCal(true);
        }
    }, []);

    const dateString = React.useMemo(() => {
        const day = date.toLocaleString('en-US', { weekday: 'long' });
        const dateOfMonth = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'long' });
        const year = date.toLocaleString('en-US', { year: 'numeric' });
        return `${day}, ${month} ${dateOfMonth}, ${year}`;
    }, [date]);

    return (
        <div className="homePageWrapper">
            <div className="infoSection">
                <h1>What is the <b>RAVLT</b>?</h1>
                <p>The Rey Auditory Verbal Learning Test (RAVLT) is a neuropsychological tool widely used to assess functions such as attention, memory, and learning ability in the auditory-verbal domain.</p>
            </div>
            <div className="cardArea">
                <div className="card">
                    <h2 className="cardHeader">Test Availability</h2>
                    {!showCal &&
                        <>

                            <p>Testing is now available. Click below to take the test.</p>
                            <button className="takeTestButton" onClick={props.launchTest}>Take Test</button>
                            <br />
                            <br />
                        </>
                    }
                    {showCal &&
                        <>
                            <p>Your next test will be available on {dateString}.</p>
                            <div className="examCal">
                                <Calendar
                                    //className="examCal"
                                    locale="en-US"
                                    defaultValue={date} //should be next date of exam
                                    minDate={date}
                                    maxDate={date}
                                    showNeighboringMonth={false}
                                ></Calendar>
                            </div>
                        </>
                    }
                </div>
                <div className="card">
                    <h2 className="cardHeader">Past Results</h2>
                    <History data={props.historyData}></History>
                </div>
            </div>
        </div>
    );
}