export enum Translation {
    // Navigation and Login
    LOGIN = "LOGIN",
    HOME = "HOME",
    EXAM = "EXAM",
    HISTORY = "HISTORY",
    USERNAME = "USERNAME",
    PASSWORD = "PASSWORD",
    SUBMIT = "SUBMIT",
    LOG_IN = "LOG_IN",

    // Exam
    VOICE_NOT_SUPPORTED = "VOICE_NOT_SUPPORTED",
    SETUP_PREAMBLE = "SETUP_PREAMBLE",
    EXAM_INSTRUCTIONS = "EXAM_INSTRUCTIONS",
    EXAM_INSTRUCTIONS_SECOND = "EXAM_INSTRUCTIONS_SECOND",
    EXAM_INSTRUCTIONS_REPEAT = "EXAM_INSTRUCTIONS_REPEAT",
    EXAM_INSTRUCTIONS_READING = "EXAM_INSTRUCTIONS_READING",
    EXAM_INSTRUCTIONS_LISTENING = "EXAM_INSTRUCTION_LISTENING",

    // General
    MEMORY_AIR = "MEMORY_AIR",
}