export const sessionUsername = "MEMORY_AIR_USERNAME";
export const sessionLanguage = "MEMORY_AIR_LANGUAGE";

export const enum COLOR {
    // PRIMARY
    COPPER = "#C68734",
    SKY = "#3492C6",

    // SECONDARY
    LIGHT_GREEN = "#3EC7AE",
    COFFEE = "#7A4E14",

    // TERTIARY
    TOAST = "#605940",
    TAN = "#FFB95C",
    NAVY = "08517A",
    MALLARD = "#2C7A6C",
    SUNFLOWER = "#FACA28",
    BURNT_RED = "#9E2C00",
    PINK = "#E02460",
    SUNKISS = "#C7552A"
};

export const enum HISTORY_DOMAIN {
    MINIMUM = 0,
    MAXIMUM = 15
};

export const enum SERVER {
    LOGIN = "https://api.memoryair.com/login",
    LOGIN_STATE = "https://api.memoryair.com/loginState",
    LOGOUT = "https://api.memoryair.com/logout",
    REGISTER = "https://api.memoryair.com/register",
    ADD_SCORE = "https://api.memoryair.com/addScore",
};