import { raiseMicrophoneChangedEvent } from "../../utils/utils";

let MICROPHONE_MANAGER_INSTANCE: MicrophoneManager;

export class MicrophoneManager {
    constructor() {
        this.bindMediaChangeEvent();
    }

    static Instance = (): MicrophoneManager => {
        if (MICROPHONE_MANAGER_INSTANCE === null || typeof MICROPHONE_MANAGER_INSTANCE === "undefined") {
            MICROPHONE_MANAGER_INSTANCE = new MicrophoneManager();
        }
        return MICROPHONE_MANAGER_INSTANCE;
    }

    private bindMediaChangeEvent = (): void => {
        navigator.mediaDevices.ondevicechange = (event) => {
            raiseMicrophoneChangedEvent();
        };
    }
}