import '../styles/Login.css';
import React from "react";
import { SERVER } from '../utils/constants';

export interface loginProps {
    setIsTakingPromotionalTest(isTakingPromotionalTest: boolean): void;
}

export const Login: React.FC<loginProps> = (props: loginProps) => {
    const takePromotionalTest = (): void => {
        props.setIsTakingPromotionalTest(true);
    };

    const [isRegistering, setIsRegistering] = React.useState<boolean>(false);

    const showRegister = (): void => {
        setIsRegistering(true);
    }

    const showLogin = (): void => {
        setIsRegistering(false);
    }

    return (
        <div className="loginPage">
            <div className="headerRow">
                <h1>Welcome to Memory Air's <br></br><b className="bLogin">Memory</b> Testing</h1>
            </div>
            <div className="textRow">
                <div className="textSection">
                    <h2>
                    Conveniently take the complete virtual Rey Auditory Verbal Learning Test and track your own memory improvement from the comfort of your own home or anywhere with internet and microphone access.
                    </h2>

                    <p>Put your memory to the test by taking a trial test <a onClick={takePromotionalTest}>here</a>.</p>
                    <p>Learn more about the Memory Air product <a href="https://learn.memoryair.com">here</a>.</p>

                </div>

                <div className="formSection">
                    {isRegistering == false &&
                        <div className="form">
                            <form action={SERVER.LOGIN} method="POST">
                                <label htmlFor="email">Email Address:</label>
                                <input type="email" id="email" name="email" required /><br />

                                <label htmlFor="password">Password:</label>
                                <input type="password" id="password" name="password" required /><br />

                                <div className="submitButton">
                                    <input type="submit" value="Log In" />
                                </div>
                            </form>
                            <br />
                            <div className="swapSection"> 
                                <p className="swapText">Don't have an account?</p>
                                <button className="swapFormButton" onClick={showRegister}>Register</button>
                            </div>
                        </div>
                    }

                    {isRegistering &&
                        <div className="form">
                            <form action={SERVER.REGISTER} method="POST">
                                <label htmlFor="name">Name:</label>
                                <input type="text" id="name" name="name" required /><br />

                                <label htmlFor="dob">Date of Birth:</label>
                                <input type="date" id="dob" name="dob" required /><br />

                                <label htmlFor="email">Email Address:</label>
                                <input type="email" id="email" name="email" required /><br />

                                <label htmlFor="password">Password:</label>
                                <input type="password" id="password" name="password" required /><br />

                                <label htmlFor="verify-password">Verify Password:</label>
                                <input type="password" id="verify-password" name="verify-password" required /><br />

                                <div className="submitButton">
                                    <input type="submit" value="Register" />
                                </div>
                            </form>
                            <br />
                            <div className="swapSection"> 
                                <p className="swapText">Already registered?</p>
                                <button className="swapFormButton" onClick={showLogin}>Log In</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}