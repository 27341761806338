import { translate } from "../translate/translate";
import { Translation } from "../translate/translate_list";
import { AppLoginInfo, Tab } from "./typedefs";

// ------------
// HTML and CSS
// ------------

export const getById = (id: string): HTMLElement => {
    let element = document.getElementById(id);
    return element as HTMLElement;
}

// -------
// GENERAL
// -------

export const equalsIgnoreCase = (a: string, b: string): boolean =>
{
    return a.toLowerCase() === b.toLowerCase();
}

export const currentYear = () : string => {
    return (new Date()).getFullYear().toString();
}

// ----------
// NAVIGATION
// ----------

export const getTabFromUrl = (): Tab => {
    let url = document.URL;
    if (url.endsWith(Tab.LOGIN)) { return Tab.LOGIN; }
    if (url.endsWith(Tab.HOME)) { return Tab.HOME; }
    if (url.endsWith(Tab.HISTORY)) { return Tab.HISTORY; }
    if (url.endsWith(Tab.EXAM)) { return Tab.EXAM; }

    return Tab.HOME;
}

export const switchUrlFromTab = (tab: Tab): void => {
    switch (tab) {
        case Tab.LOGIN: window.location.assign(Tab.LOGIN); break;
        case Tab.HOME: window.location.assign(Tab.HOME); break;
        case Tab.HISTORY: window.location.assign(Tab.HISTORY); break;
        case Tab.EXAM: window.location.assign(Tab.EXAM); break;
    }
    updatePageTitle(tab);
}

export const updatePageTitle = (tab: Tab) => {
    if (tab === Tab.HOME) {
        window.document.title = translate(Translation.MEMORY_AIR);
        return;
    }

    let title = document.title;
    switch (tab) {
        case Tab.LOGIN: title = translate(Translation.LOGIN); break;
        case Tab.HISTORY: title = translate(Translation.HISTORY); break;
        case Tab.EXAM: title = translate(Translation.EXAM); break;
    }
    if (title !== window.document.title) {
        let newTitle = translate(Translation.MEMORY_AIR) + " - " + title;
        window.document.title = newTitle;
    }
}

// ------------------------
// MICROPHONE + PERMISSIONS
// ------------------------
export const microphoneChangedEvent = "MICROPHONE_CHANGED_EVENT";
export const raiseMicrophoneChangedEvent = () => {
    const event = new CustomEvent(microphoneChangedEvent);
    document.dispatchEvent(event);
}



// ------------------------
//       AUDIO TEST
// ------------------------
export const instructionsCompletedEvent = "INSTRUCTIONS_COMPLETED_EVENT";
export const raiseInstructionsCompletedEvent = () => {
    const event = new CustomEvent(instructionsCompletedEvent);
    document.dispatchEvent(event);
}