import React, { MutableRefObject } from "react";
import { FakeServer } from "../../FAKE_SERVER";
import { translate } from "../../translate/translate";
import { Translation } from "../../translate/translate_list";
import { addExamScore } from "../../utils/server_api";
import { ReyTestPhase } from "../../utils/typedefs";
import { instructionsCompletedEvent, microphoneChangedEvent } from "../../utils/utils";
import { Narrator } from "../narrator/narrator";
import { Recognizer } from "../recognizer/recognizer";


export interface reyTestProps {
    roundsCompleted: MutableRefObject<number>;
    incrementRoundsCompleted(): void;
    finishTest(): void;
    wordList: string[];
    isPromotionalTest?: boolean;
}

export const ReyTest: React.FC<reyTestProps> = (props: reyTestProps) => {
    const [numCorrect, setNumCorrect] = React.useState<number>(0);
    const [_examPhase, _setExamPhase] = React.useState<ReyTestPhase>(ReyTestPhase.UNSTARTED);
    const examPhase = React.useRef(_examPhase);
    const [showProceedButton, setShowProceedButton] = React.useState<boolean>(false);
    const setExamPhase = (newPhase: ReyTestPhase) => {
        examPhase.current = newPhase;
        _setExamPhase(newPhase);
    }

    let correctWords: string[] = [];


    React.useEffect(() => {
        window.addEventListener(microphoneChangedEvent, (e) => handleInput((e as CustomEvent).detail));
        Recognizer.Instance().onresult = (event: { results: string | any[]; }) => {
            var input = event.results[event.results.length - 1][0].transcript.trim().toLowerCase();
            if (props.roundsCompleted.current == 4) {
                handleInput(input);
            }   
        }
        setInitialExamPhase();
    }, []);

    const handleInput = (input: string): void => {
        if (input.includes(" ")) {
            for (let word of input.split(" ")) {
                handleWord(word);
            }
        } else {
            handleWord(input);
        }
    }

    const handleWord = (word: string): void => {
        if (props.wordList.includes(word) && !correctWords.includes(word)) {
            setNumCorrect(numCorrect => numCorrect + 1);
            correctWords.push(word);
        }
    }

    const getCorrectWords = React.useCallback(() => {
        return correctWords.join(", ");
    }, []);

    const setInitialExamPhase = () =>
    {
        // later this should deal with the session variables and adjust accordingly
        setExamPhase(ReyTestPhase.READING);
        Narrator.Instance().readWords();
    }

    // this needs to allow time for the recognizer to finish processing before it moves forward... factor that in first
    // we may want to not use continuous: is that more processor-intensive?
    // run some smoke tests
    const handleProceed = () => {
        setShowProceedButton(false);
        if (examPhase.current === ReyTestPhase.INSTRUCTIONS_SECOND || examPhase.current === ReyTestPhase.INSTRUCTIONS_REPEAT) {
            setExamPhase(ReyTestPhase.READING);
            Narrator.Instance().readWords();
            Recognizer.Instance().stop();
        } else if (examPhase.current === ReyTestPhase.READING) {
            Recognizer.Instance().start();
            setExamPhase(ReyTestPhase.LISTENING);
        } else if (examPhase.current === ReyTestPhase.LISTENING) {
            Recognizer.Instance().stop();
            props.incrementRoundsCompleted();

            if (props.roundsCompleted.current === 5) {
                submitExamData();
                setExamPhase(ReyTestPhase.FINISHED);
                //props.finishTest();
            } else if (props.roundsCompleted.current === 1){
                setExamPhase(ReyTestPhase.INSTRUCTIONS_SECOND);
                Narrator.Instance().readSecondInstructions();
            } else {
                setExamPhase(ReyTestPhase.INSTRUCTIONS_REPEAT);
                Narrator.Instance().readRepeatInstructions();
            }
        }
    };


    const submitExamData = async () => {
        if ((!props.isPromotionalTest)) {
            addExamScore(numCorrect);
        }
    }

    // when instructions are done being read, enable the current proceed button
    React.useEffect(() => {
        document.addEventListener(instructionsCompletedEvent, () => {
            if (examPhase.current == ReyTestPhase.READING) {
                handleProceed();
            } else {
                setShowProceedButton(true);
            }
        });
    }, []);
    
    return (
        <>
        {examPhase.current === ReyTestPhase.UNSTARTED && 
            <></>
        }
        {examPhase.current === ReyTestPhase.INSTRUCTIONS_SECOND &&
            <div>
                {translate(Translation.EXAM_INSTRUCTIONS_SECOND)}
            </div>
        }
        {examPhase.current === ReyTestPhase.INSTRUCTIONS_REPEAT &&
            <div>
                {translate(Translation.EXAM_INSTRUCTIONS_REPEAT)}
            </div>
        }
        {examPhase.current === ReyTestPhase.READING &&
            <div>
                {translate(Translation.EXAM_INSTRUCTIONS_READING)}
            </div>
        }
        {examPhase.current === ReyTestPhase.LISTENING &&
            <div>
                {translate(Translation.EXAM_INSTRUCTIONS_LISTENING)}
            </div>
        }
        {examPhase.current == ReyTestPhase.LISTENING && 
            <div style={{width: "100%"}}>
                <button onClick={handleProceed}>I'm Finished</button>
            </div>   
        }
        {(showProceedButton && (examPhase.current == ReyTestPhase.UNSTARTED || examPhase.current == ReyTestPhase.INSTRUCTIONS_SECOND || examPhase.current == ReyTestPhase.INSTRUCTIONS_REPEAT)) && 
            <div style={{width: "100%"}}>
                <button onClick={handleProceed}>I'm Ready</button>
            </div>   
        }

        {
            examPhase.current === ReyTestPhase.FINISHED && 
            <div>
                You got {numCorrect} words correct: <br/>
                {getCorrectWords()}<br/>

                <button className="takeTestButton" onClick={() => window.location.reload()}>Return Home</button>

            </div>
        }
        
        </>
    )
}
