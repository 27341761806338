import { ExamInstructionAudio, Language } from "./utils/typedefs";

import instruction_source from "./exam/narrator/recordings/instructions_first.mp3";
import instruction_second from "./exam/narrator/recordings/instructions_second.mp3";
import instruction_repeat from "./exam/narrator/recordings/instructions_repeat.mp3";

import audio_DRUM from "./exam/narrator/recordings/List_DRUM.mp3";
import audio_VIOLIN from "./exam/narrator/recordings/List_VIOLIN.mp3";
import audio_ORANGE from "./exam/narrator/recordings/List_ORANGE.mp3";
import audio_PIPE from "./exam/narrator/recordings/List_PIPE.mp3";
import audio_BENCH from "./exam/narrator/recordings/List_BENCH.mp3";
import audio_DOLL from "./exam/narrator/recordings/List_DOLL.mp3";
import audio_DISH from "./exam/narrator/recordings/List_DISH.mp3"
import audio_BOOK from "./exam/narrator/recordings/List_BOOK.mp3";
import audio_BOWL from "./exam/narrator/recordings/List_BOWL.mp3";

export class FakeServer {
    static NUM_WORD_LISTS = 9;

    static GetWordList = (numTest: number): string[] => {
        let cycle = numTest % FakeServer.NUM_WORD_LISTS;
        switch (cycle) {
            case 0: return ["drum", "curtain", "bell", "belle", "coffee", "school", "parent",
                "moon", "garden", "hat", "farmer", "nose", "nos", "knows", "turkey", "color",
                "colour", "house", "hows", "river"];
            case 1: return ["book", "flower", "flour", "train", "rug", "meadow", "harp", "salt",
                "finger", "apple", "chimney", "button", "log", "key", "rattle", "gold"];
            case 2: return ["doll", "mirror", "nail", "sailor", "heart", "desert", "face",
                "letter", "bed", "machine", "milk", "helmet", "music", "horse", "hoarse", "road",
                "rode"];
            case 3: return ["pipe", "wall", "alarm", "sugar", "student", "mother", "star", "painting",
                "bag", "wheat", "mouth", "chicken", "sound", "door", "stream"];
            case 4: return ["violin", "tree", "scarf", "ham", "suitcase", "cousin", "earth", "stairs",
                "stares", "dog", "banana", "town", "radio", "hunter", "bucket", "field"];
            case 5: return ["bench", "officer", "cage", "sock", "fridge", "cliff", "bottle", "soap",
                "sky", "ship", "goat", "bullet", "paper", "chapel", "crab"];
            case 6: return ["dish", "jester", "hill", "coat", "tool", "forest", "water", "ladder", "latter",
                "girl", "foot", "shield", "pie", "insect", "ball", "car"];
            case 7: return ["bowl", "dawn", "judge", "grant", "insect", "plane", "plain", "county", "pool",
                "seed", "sheep", "meal", "boat", "bottle", "peach", "chair"];
            case 8: return ["orange", "table", "toad", "corn", "bus", "chin", "beach", "soap", "hotel",
                "donkey", "spider", "money", "book", "soldier", "padlock"];
        }
        return ["drum", "curtain", "bell", "belle", "coffee", "school", "parent",
            "moon", "garden", "hat", "farmer", "nose", "nos", "knows", "turkey", "color",
            "colour", "house", "hows", "river"];
    }

    static GetWordAudio = (numTest: number): string => {
        let cycle = numTest % FakeServer.NUM_WORD_LISTS;
        switch (cycle) {
            case 0: return audio_DRUM;
            case 1: return audio_BOOK;
            case 2: return audio_DOLL;
            case 3: return audio_PIPE;
            case 4: return audio_VIOLIN;
            case 5: return audio_BENCH;
            case 6: return audio_DISH;
            case 7: return audio_BOWL;
            case 8: return audio_ORANGE;
        }
        return audio_DRUM;
    }

    static GetInstructionAudio = (): ExamInstructionAudio => {
        return {
            first: instruction_source,
            second: instruction_second,
            repeat: instruction_repeat
        };
    }
}