import "../styles/History.css";
import React from "react";
import { HistoryChart } from "../components/historyChart";
import { ExamScore, HistoryData } from "../utils/typedefs";

export interface historyProps {
    data: HistoryData[];
}

export const History: React.FC<historyProps> = (props: historyProps) => {
    const [examData, setExamData] = React.useState<ExamScore[]>([]);

    React.useEffect(() => {
        const fetchExamData = async () => {
            const data: HistoryData[] = props.data;
            if(data) {
                let transformedData: ExamScore[] = [];
    
                for(let nextScore of data)
                {
                    transformedData.push({
                        score: nextScore.score,
                        date: nextScore.date.toDateString().slice(nextScore.date.toDateString().indexOf(" ")),
                    })
                }
    
                setExamData([...transformedData])
            }
        }

        fetchExamData();
    }, []);

    return (
        <div className="historyWrapper">
            <HistoryChart 
                data={examData}
                ></HistoryChart>
        </div>
    );
}