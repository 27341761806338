export enum Tab {
    LOGIN = "login",
    HOME = "home",
    HISTORY = "history",
    EXAM = "exam"
};

export enum ExamRound {
    UNKNOWN,
    UNABLE,
    UNSTARTED,
    SETUP,
    MICROPHONE_CHANGED_AFTER_SETUP,
    INSTRUCTIONS,
    REY_TEST,
    FINISHED,
}

export enum ReyTestPhase {
    UNSTARTED,
    INSTRUCTIONS_SECOND,
    INSTRUCTIONS_REPEAT,
    READING,
    LISTENING,
    FINISHED
}

export const SCORE_MANAGER_SESSION_VARIABLE = "SCORE_MANAGER_SESSION_VARIABLE";

export enum MicrophoneState {
    NOT_FOUND,
    NOT_ALLOWED,
    CONNECTED,
    UNKNOWN
}

export enum Language {
    ENGLISH = "ENGLISH"
}

export const INVALID_STRING = "Invalid string";

export interface HistoryData {
    date: Date
    score: number
}

export interface AppLoginInfo {
    isLoggedIn: boolean;
    user: User;
}

export interface NextTestInfo {
    nextTestDate: Date;
    canTakeTest: boolean;
}

export interface ExamScoreEntry {
    sid: number;
    score: number;
    date: string;
}

export interface ExamScore {
    score: number,
    date: string
}

export interface GetExamScoresResponse {
    data: ExamScoreEntry[];
}

export interface ExamInstructionAudio {
    first: string;
    second: string;
    repeat: string;
}

export interface User {
    name: String,
    email: string,
    scores: ExamScore[],
    nextTestDate: string
}

export interface ExamScore {
    score: number,
    date: string
}