import { SERVER } from "./constants";
import { HistoryData, ExamScore } from "./typedefs";

export const getLoginState = async () => {
    return fetch(`${SERVER.LOGIN_STATE}`, {
        method: "GET",
        credentials: "include",
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(async response => {
        let json = await response.json();
        return {
            isLoggedIn: json['isLoggedIn'],
            user: json['user']
        }
    });
}

export const logOut = async () => {
    return fetch(`${SERVER.LOGOUT}`, {
        method: "GET",
        credentials: "include",
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(async response => {
        window.location.href = response.url;
    });
}

export const addExamScore = async (numCorrect: number) => {
    return fetch(`${SERVER.ADD_SCORE}`, {
        method: "POST",
        credentials: "include",
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: `score=${numCorrect}`
    });
}

export const getExamScores = async (scores: ExamScore[]): Promise<HistoryData[]> => {   
    let result: HistoryData[] = [];

    for(let nextScore of scores)
    {
        result.push({
            score: nextScore.score,
            date: new Date(nextScore.date),
        });
    }

    return result;
}