import React from "react";
import { COLOR } from "../utils/constants";
import { MicrophoneState } from "../utils/typedefs";
import { equalsIgnoreCase, getById, microphoneChangedEvent } from "../utils/utils";
import { Recognizer } from "./recognizer/recognizer";
import "../styles/Exam.css";

const MICROPHONE_DROPDOWN_ID = "microphoneDropdownList";

const SETUP_WORD_LIST = ["House", "Friend", "Circle"];
const SETUP_WORD_1 = "setupWord_1";
const SETUP_WORD_2 = "setupWord_2";
const SETUP_WORD_3 = "setupWord_3";


export interface setupProps {
    moveToInstructions(): void;
}


export const Setup: React.FC<setupProps> = (props: setupProps) => {
    const [microphoneState, setMicrophoneState] = React.useState<MicrophoneState>(MicrophoneState.UNKNOWN);
    const [microphones, setMicrophones] = React.useState<MediaDeviceInfo[]>([]);
    const [setupWordsCompleted, setSetupWordsCompleted] = React.useState<boolean[]>([false, false, false]);
    const [setupCompleted, setSetupCompleted] = React.useState<boolean>(false);

    // startup code
    React.useEffect(() => {
        bindMicrophoneChangeHandler();
        updateMicrophoneState();
        setupResponseToSpeech();
    }, []);

    const bindMicrophoneChangeHandler = () => {
        document.addEventListener(microphoneChangedEvent, () => {
            updateMicrophoneState();
        });
    }

    const updateMicrophoneState = () => {
        navigator.mediaDevices.getUserMedia(
            {video: false, audio: true})
            .then(media => {
                setMicrophoneState(MicrophoneState.CONNECTED);
                Recognizer.Instance().start();
            })
            .catch(error => {
                switch (error.name) {
                    case "NotFoundError": setMicrophoneState(MicrophoneState.NOT_FOUND); break;
                    case "NotAllowedError": setMicrophoneState(MicrophoneState.NOT_ALLOWED); break;
                    default: setMicrophoneState(MicrophoneState.UNKNOWN);
                }
                Recognizer.Instance().stop();
            }).finally(() => {
                updateMicrophoneList();
            })
    }

    const updateMicrophoneList = () => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            let microphoneList: MediaDeviceInfo[] = [];
            devices.forEach((device) => {
                if (device.kind === "audioinput") {
                    microphoneList.push(device);
                }
            });
            setMicrophones(microphoneList);
        })
    }

    const changeSelectedMicrophone = (event: any) => {
        navigator.mediaDevices.getUserMedia(
            {audio: {
                deviceId: event.target.value
            }});
    }

    // dropdown and selected device
    const microphoneDropdown = React.useMemo(() => {
        const options: {value: string, label: string, key: string}[] = [];
        for (let microphone of microphones) {
            options.push({value: microphone.deviceId, label: microphone.label, key: microphone.deviceId});
        };
        return (
            <select id={MICROPHONE_DROPDOWN_ID} onChange={changeSelectedMicrophone}>
                {options.map(({ value, label, key }, index) => <option value={value} key={key}>{label}</option>)}
            </select>
        );
    }, [microphones]);

    const updateCompletedWords = (word: number) => {
        let completedWords =  setupWordsCompleted;
        completedWords[word] = true;
        setSetupWordsCompleted(completedWords);
        setSetupCompleted(completedWords.every(word => word));
    }

    const moveToExam = () => {
        props.moveToInstructions();
    }

    const setupResponseToSpeech = () => {
        Recognizer.Instance().start();
        Recognizer.Instance().onresult = (event: { results: string | any[]; }) => {
            var word = event.results[event.results.length - 1][0].transcript.trim().toLowerCase();
            handleSpeechInput(word);
        }
    }

    const handleSpeechInput = (word: string) => {
        if (equalsIgnoreCase(word, SETUP_WORD_LIST[0]) || word.includes(SETUP_WORD_LIST[0])) { getById(SETUP_WORD_1).style.color = COLOR.LIGHT_GREEN; updateCompletedWords(0); }
        else if (equalsIgnoreCase(word, SETUP_WORD_LIST[1]) || word.includes(SETUP_WORD_LIST[1])) { getById(SETUP_WORD_2).style.color = COLOR.LIGHT_GREEN; updateCompletedWords(1); }
        else if (equalsIgnoreCase(word, SETUP_WORD_LIST[2]) || word.includes(SETUP_WORD_LIST[2])) { getById(SETUP_WORD_3).style.color = COLOR.LIGHT_GREEN; updateCompletedWords(2); }
    }
    

    return (
    <>
        {microphoneState === MicrophoneState.NOT_FOUND &&
            <div>
                There is no input device detected. Please connect a microphone.
            </div>
        }
        {microphoneState === MicrophoneState.NOT_ALLOWED &&
            <div>
                In order to take the test, you must grant permission to access your microphone.
            </div>
        }
        {microphoneState === MicrophoneState.CONNECTED &&
            <div>
                {microphoneDropdown}
                <div id="setupInstructions">To make sure your selected microphone is suitable for the test, say each of the following words, one at a time:</div>
                <div id={SETUP_WORD_1}>{SETUP_WORD_LIST[0]}</div>
                <div id={SETUP_WORD_2}>{SETUP_WORD_LIST[1]}</div>
                <div id={SETUP_WORD_3}>{SETUP_WORD_LIST[2]}</div>
                {setupCompleted &&
                    <button onClick={moveToExam}>Proceed to Exam</button>
                }
            </div>
        } 
    </>
    );
}