import './styles/App.css';
import React from 'react';

import { HistoryData } from './utils/typedefs';
import { Exam } from './exam/exam';
import { Home } from './home/home';
import { currentYear } from './utils/utils';

import { getExamScores, getLoginState, logOut } from './utils/server_api';
import { Login } from './login/login';
import { Narrator } from './exam/narrator/narrator';

const logo = require("./logo.svg") as string;

function App() {
  
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
  const [loginStateDetermined, setLoginStateDetermined] = React.useState<boolean>(false);
  const [isTakingTest, setIsTakingTest] = React.useState<boolean>(false);
  const [isTakingPromotionalTest, setIsTakingPromotionalTest] = React.useState<boolean>(false);
  const [userName, setUserName] = React.useState<string>("");
  const [nextExamDate, setNextExamDate] = React.useState<Date>(new Date());
  const [pastScores, setPastScores] = React.useState<HistoryData[]>([]);
  const [numTest, setNumTest] = React.useState<number>(0);
  
  React.useEffect(() => {
    window.speechSynthesis.cancel();
    getLoginState().then(async (state) => {
      if (state.isLoggedIn) {
        setPastScores(await getExamScores(state.user.scores));
        setNextExamDate(new Date(state.user.nextTestDate));
        setUserName(state.user.name);
        let tNumTest = state.user.scores.length;
        console.log(tNumTest);
        setNumTest(tNumTest);
        Narrator.Instance().setAudio(tNumTest);
      }
      setIsLoggedIn(state.isLoggedIn);
      setLoginStateDetermined(true);
    })
    }, []);
  

  return (
    <div className="App">
      <div className="header">
        <a href="/" style={{height: "100%"}}>
          <img id="logo" src={require("./images/logo_black.png")} alt="Logo"></img>
        </a>
        {isLoggedIn &&
          <div className="profileDropdown">
          <img id="profile" src={require("./images/profile-icon.png")} alt="Profile"></img>
          <div className="dropdownContent">
            <p className="userName">{userName}</p>
            <p className="dropdownLabel" onClick={logOut}>Logout</p>
          </div>
        </div>
        }
      </div>

      {((!isLoggedIn) && (!isTakingPromotionalTest) && loginStateDetermined) &&
        <Login setIsTakingPromotionalTest={setIsTakingPromotionalTest}/>
      }
      
      {isLoggedIn && !isTakingTest &&
        <Home historyData={pastScores} nextExamDate={nextExamDate} launchTest={() => {setIsTakingTest(true)}} isLoggedIn={isLoggedIn}/>
      }

      {((isLoggedIn && isTakingTest) || isTakingPromotionalTest) &&
        <div className='examBox'>
          <Exam numTest={numTest} finishTest={() => {
            setIsTakingTest(false);
            setIsTakingPromotionalTest(false);
            }} isPromotionalTest={isTakingPromotionalTest}></Exam>
        </div>
      }

      <footer className={(isLoggedIn && !isTakingTest) ? "footer" : "footerFixed"}>
        <p>&copy;Copyright {currentYear()} Science Lab 3.</p>
        <p>All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default App;