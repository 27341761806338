import { FakeServer } from "../../FAKE_SERVER";
import { ExamInstructionAudio } from "../../utils/typedefs";
import { raiseInstructionsCompletedEvent } from "../../utils/utils";

let NARRATOR_INSTANCE: Narrator;

export class Narrator {
    static _wordAudio: HTMLAudioElement;
    static _firstInstructions: HTMLAudioElement;
    static _secondInstructions: HTMLAudioElement;
    static _repeatInstructions: HTMLAudioElement;
    static _clearLastTimeout: Function;
    static _lastAudio: HTMLAudioElement;

    constructor() {
        Narrator._wordAudio = new Audio(FakeServer.GetWordAudio(0));

        let sources: ExamInstructionAudio = FakeServer.GetInstructionAudio();
        Narrator._firstInstructions = new Audio(sources.first);
        Narrator._secondInstructions = new Audio(sources.second);
        Narrator._repeatInstructions = new Audio(sources.repeat);

        Narrator._clearLastTimeout = () => {};
        Narrator._lastAudio = Narrator._firstInstructions;
    }

    static Instance(): Narrator {
        if (NARRATOR_INSTANCE === null || typeof NARRATOR_INSTANCE === "undefined") {
            NARRATOR_INSTANCE = new Narrator();
        }
        return NARRATOR_INSTANCE;
    }

    setAudio = (numTest: number) => {
        Narrator._wordAudio = new Audio(FakeServer.GetWordAudio(numTest));
        //
    }

    dispatchPlay = (audio: HTMLAudioElement) => {
        audio.play();
        Narrator._lastAudio = audio;

        let timeoutId = setTimeout(() => {
            raiseInstructionsCompletedEvent();
        }, audio.duration * 1000);
        Narrator._clearLastTimeout = () => {
            clearTimeout(timeoutId);
        }
    }

    readFirstInstructions = () => {
        this.dispatchPlay(Narrator._firstInstructions);
    }

    readSecondInstructions = () => {
        this.dispatchPlay(Narrator._secondInstructions);
    }

    readRepeatInstructions = () => {
        this.dispatchPlay(Narrator._repeatInstructions);
    }

    readWords = () => {
        this.dispatchPlay(Narrator._wordAudio);
    }

    stop = () => {
        Narrator._clearLastTimeout();
        Narrator._lastAudio.pause();
        Narrator._lastAudio.currentTime = 0;
    }
}