import "../styles/Exam.css";
import "../styles/App.css";
import React from "react";
import { FakeServer } from "../FAKE_SERVER";
import { ExamRound, MicrophoneState } from "../utils/typedefs";
import { instructionsCompletedEvent, microphoneChangedEvent } from "../utils/utils";
import { MicrophoneManager } from "./recognizer/microphoneManager";
import { ReyTest } from "./reyTest/reyTest";
import { Setup } from "./setup";
import { Narrator } from "./narrator/narrator";
import { Translation } from "../translate/translate_list";
import { translate } from "../translate/translate";
import { Recognizer } from "./recognizer/recognizer";


export interface examProps {
    finishTest(): void;
    isPromotionalTest?: boolean;
    numTest: number;
}


export const Exam: React.FC<examProps> = (props: examProps) => {
    // current round should depend on session variable state!
    const [_currentRound, _setCurrentRound] = React.useState<ExamRound>(ExamRound.UNSTARTED);
    const [showProceedButton, setShowProceedButton] = React.useState<boolean>(false);
    const currentRound = React.useRef(_currentRound); // needed to propagate round change to the event handler
    const setCurrentRound = (newRound: ExamRound) => {
        currentRound.current = newRound;
        _setCurrentRound(newRound);
    }

    Narrator.Instance();

    const [_roundsCompleted, setRoundsCompleted] = React.useState<number>(0);
    const roundsCompleted = React.useRef(_roundsCompleted);
    const incrementRoundsCompleted = () => {
        roundsCompleted.current += 1;
        setRoundsCompleted(numRounds => numRounds + 1);
    }

    const [wordList, setWordList] = React.useState<string[]>([]);

    const setupExam = () => {
        MicrophoneManager.Instance();
        bindMicrophoneChangeHandler();
        let tWordList = FakeServer.GetWordList(props.numTest);
        setWordList(tWordList);
        bindInstructionsCompleteHandler();
    }

    React.useEffect(() => {
        setupExam();
    }, []);

    React.useEffect(() => {
        setWordList(FakeServer.GetWordList(props.numTest));
    }, [props.numTest]);

    
    // --------------------
    // ESTABLISH NAVIGATION
    // --------------------

    const moveToSetup = () => {
        setCurrentRound(ExamRound.SETUP);
        currentRound.current = ExamRound.SETUP;
    }

    const moveToInstructions = () => {
        setCurrentRound(ExamRound.INSTRUCTIONS);
        currentRound.current = ExamRound.INSTRUCTIONS;
        Narrator.Instance().readFirstInstructions();
    }

    const moveToExam = () => {
        setCurrentRound(ExamRound.REY_TEST);
        currentRound.current = ExamRound.REY_TEST;
    }

    const bindMicrophoneChangeHandler = () => {
        document.addEventListener(microphoneChangedEvent, () => {
            let round = currentRound.current;
            if (round === ExamRound.INSTRUCTIONS || round === ExamRound.REY_TEST) {
                setCurrentRound(ExamRound.MICROPHONE_CHANGED_AFTER_SETUP);
            }
            Narrator.Instance().stop();
            Recognizer.Instance().stop();
        });
    }

    const bindInstructionsCompleteHandler = () => {
        document.addEventListener(instructionsCompletedEvent, () => {
            setShowProceedButton(true);
        })
    }
    
    return (
        <div className="examWrapper">
            <div className="examHeader">
                <h1>Rey Auditory Verbal Learning Test</h1>
            </div>

            <div className="examArea">
                {currentRound.current === ExamRound.UNSTARTED && 
                <div>
                    Before you take the memory test, we need to make sure your microphone is working properly and that you are in an environment conducive to yielding accurate test results. Please find a quiet area, connect your microphone, and then press Proceed.
                    <div>
                        <button onClick={moveToSetup}>Proceed</button>
                    </div>
                </div>
                }

                {currentRound.current === ExamRound.MICROPHONE_CHANGED_AFTER_SETUP && 
                <div>
                    Your microphone changed or became unplugged during the exam. You must complete the setup again before proceeding.
                    <div>
                        <button onClick={moveToSetup}>Proceed</button>
                    </div>
                </div>
                }

                {currentRound.current === ExamRound.SETUP && 
                <div>
                    <Setup moveToInstructions={moveToInstructions}/>
                </div>
                }

                {currentRound.current === ExamRound.INSTRUCTIONS &&
                <div>
                    {translate(Translation.EXAM_INSTRUCTIONS)}
                    <div>
                        {showProceedButton && 
                            <button onClick={moveToExam}>Continue</button>
                        }
                    </div>
                </div>        
                }

                {currentRound.current === ExamRound.REY_TEST && 
                    <ReyTest finishTest={props.finishTest} roundsCompleted={roundsCompleted} incrementRoundsCompleted={incrementRoundsCompleted} wordList={wordList} isPromotionalTest={props.isPromotionalTest}/>
                }
            </div>

        </div>
    );
}